import React, { useState, useEffect } from "react"
import { Global, css, keyframes } from "@emotion/core"
import tw from "tailwind.macro"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout, { usePage } from "../components/layout"
import SEO from "../components/seo"
import {
  beige,
  brownLight,
  brown,
  cream,
  brownDark,
  green,
  greenDark,
  black,
} from "../style"
import { useShopify } from "../components/shopify"

function buildSimpleRepr({ node }) {
  return {
    ...node,
    ...node.variants[0],
    title: node.title,
    shopifyId: node.shopifyId,
    description: node.descriptionHtml,
    images: node.images.map(image => ({
      fluid: image.localFile.childImageSharp.fluid,
    })),
  }
}

export default function IndexPage({ data }) {
  let nodes = data.allShopifyProduct.edges.map(buildSimpleRepr)
  return (
    <Layout>
      <SEO title="inventory" />

      {nodes.map(node => (
        <Product node={node} />
      ))}
    </Layout>
  )
}

function Product({ node }) {
  let {
    availableForSale,
    variants: [{ shopifyId: variantId }],
  } = node
  return (
    <div css={{ ...tw`mb-8`, color: brownLight }}>
      {node.images && node.images[0] && (
        <Img
          imgStyle={
            availableForSale ? {} : { filter: `grayscale(0.2)`, opacity: 0.5 }
          }
          fluid={node.images[0].fluid}
        />
      )}
      <div
        css={tw`flex flex-row items-center justify-between mt-2 mb-1 text-xl`}
      >
        <h3
          css={{
            ...(availableForSale ? {} : tw`line-through`),
            ...tw`m-0 text-xl`,
            color: black,
          }}
        >
          {node.title}
          {availableForSale ? "" : " (sold)"}
        </h3>

        {availableForSale && (
          <Purchase amount={node.priceV2.amount} variantId={variantId} />
        )}
      </div>

      <p
        css={{ color: black }}
        dangerouslySetInnerHTML={{ __html: node.description }}
      ></p>
    </div>
  )
}

function Purchase({ variantId, amount }) {
  let { quickBuyItem, checkout } = useShopify()
  let { setLoading, loading } = usePage()

  return (
    <button
      type="button"
      disabled={loading}
      css={{
        ...tw`py-2 px-4 rounded text-lg`,
        border: 0,
        borderBottom: `2px solid ${greenDark}`,
        borderRight: `2px solid ${greenDark}`,
        backgroundColor: green,
        color: black,
        fontFamily: `OggWeb`,
      }}
      onClick={() => {
        setLoading(true)
        quickBuyItem(variantId)
      }}
    >
      purchase for <Amount amount={amount} />
    </button>
  )
}

function Amount({ amount }) {
  let normalized = amount
  if (amount.endsWith(".0") || amount.endsWith(".00")) {
    normalized = amount.split(".")[0]
  }
  return <span>${normalized}</span>
}

// filter: { availableForSale: { eq: true } }
export let pageQuery = graphql`
  {
    allShopifyProduct(
      sort: { fields: [availableForSale, createdAt], order: [DESC, DESC] }
    ) {
      edges {
        node {
          title
          shopifyId
          descriptionHtml
          handle
          images {
            localFile {
              childImageSharp {
                fluid(maxWidth: 256) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          availableForSale
          variants {
            shopifyId
            priceV2 {
              amount
            }
          }
        }
      }
    }
  }
`
